<template>
  <div>
    <b-link
      href="https://bit.ly/cnno2021_edital"
      target="_blank"
      variant="primary"
      style="margin-left: 5px; margin-bottom: 5px;"
      class="btn btn-primary"
    >
      <feather-icon
        icon="FileIcon"
      />
      Edital
    </b-link>
    <b-card>
      <b-card-code title="Horizontal Form With Icons">
        <validation-observer
          ref="scientificWorkForm"
          v-slot="{invalid}"
        >
          <b-form
            ref="form"
            class="repeater-form"
            :style="{height: trHeight}"
            @submit.prevent
          >
            <b-row>
              <b-col cols="12">
                <b-button
                  class="btn-icon buttonCoAutor"
                  variant="success"
                  :disabled="!validationTypeWork"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="20"
                  />
                </b-button>
              </b-col>
              <div class="typeWork">
                <input
                  v-model="typeWork"
                  type="radio"
                  name="typeWork"
                  value="series"
                  @click="typeWorkChange()"
                >
                <label for="series"> relatos de casos e série </label><br>
                <input
                  v-model="typeWork"
                  type="radio"
                  name="typeWork"
                  value="free"
                  @click="typeWorkChange()"
                >
                <label for="free"> Temas Livres </label><br>
              </div>
              <b-col
                v-for="(item, index) in coAutor"
                :id="item.id"
                :key="item.id"
                ref="col"
                cols="12"
              >
                <b-button
                  class="btn-icon buttonDeleteCoAutor"
                  variant="danger"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="20"
                  />
                </b-button>
                <b-form-group
                  label="Co-autor"
                  label-for="hi-coAutor"
                  label-cols-md="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="coAutor"
                    vid="coAutor"
                    rules="alpha_spaces|min:3"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="hi-coAutor"
                        v-model="coAutors[index]"
                        name="coAutor"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nome completo"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Titulo"
                  label-for="hi-title"
                  label-cols-md="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="title"
                    vid="title"
                    rules="required|min:3|max:120"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-textarea
                        id="title"
                        v-model="title"
                        name="title"
                        placeholder="Digite seu Titulo..."
                        rows="1"
                        max-rows="10"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <br>
                    <span id="countTitleColour">{{ countTitle }} / 120</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Resumo"
                  label-for="hi-text"
                  label-cols-md="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="text"
                    vid="text"
                    rules="required|min:3|max:2100"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-textarea
                        id="textarea"
                        v-model="text"
                        placeholder="Digite seu resumo..."
                        rows="8"
                        max-rows="10"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <br>
                    <span id="countTextColour">{{ countText }} / 2100</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Mobile"
                  label-for="hi-file"
                  label-cols-md="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="file"
                    vid="file"
                    rules="ext:pdf|size:80000"
                  >
                    <b-form-file
                      v-model="file1"
                      :state="fileState"
                      placeholder="Selecione o PDF do seu trabalho"
                      drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col offset-md="1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid"
                >
                  Enviar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <template #code>
          {{ codeHorizontalIcon }}
        </template>
      </b-card-code>
    </b-card>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BLink,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // form
      coAutor: [{ id: 1, }],
      coAutors: [],
      title: '',
      text: '',
      file1: '',
      validationTypeWork: true,
      countTitle: 0,
      countText: 0,
      fileState: null,
      typeWork: 'series',
      // validation
      required,
      nextTodoId: 2,
    }
  },
  watch: {
    coAutor() {
      if ((this.typeWork === 'series' && this.coAutor.length === 3) || (this.typeWork === 'free' && this.coAutor.length === 9)) {
        this.validationTypeWork = false
      } else {
        this.validationTypeWork = true
      }
    },
    title() {
      this.countTitle = this.title.length
      this.countTitleColour = document.getElementById('countTitleColour')
      if (this.title.length > 120) {
        this.countTitleColour.style.color = 'red'
      } else {
        this.countTitleColour.style.color = 'black'
      }
    },
    text() {
      this.countText = this.text.length
      this.countTextColour = document.getElementById('countTextColour')
      if (this.text.length > 2100) {
        this.countTextColour.style.color = 'red'
      } else {
        this.countTextColour.style.color = 'black'
      }
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    typeWorkChange() {
      this.coAutor = [{}]
      this.countTypeWork = 1
    },
    repeateAgain() {
      this.coAutor.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.coAutor.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style>
.buttonCoAutor {
  margin: 2px;
  margin-bottom: 5px;
  float: left;
}
.buttonDeleteCoAutor {
  float: right;
}
.typeWork {
  padding: 20px;
}
</style>
